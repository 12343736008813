import React from 'react'
import Img from 'gatsby-image'
import Link from '@components/Link'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import styles from '@assets/jss/views/components.js'
import { makeStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import postsStyle from '@assets/jss/posts.js'
const useStyles = makeStyles({
  ...styles,
  ...postsStyle,
})

const RecentArticles = props => {
  const { posts } = props
  const classes = useStyles()
  const isDesktop = useMediaQuery('(min-width:960px)')
  return posts.map(({ node }) => {
    const title = node.frontmatter.title || node.fields.slug
    const contentClass = node.frontmatter.featuredImage
      ? classes.content
      : classes.noImage
    const FeaturedImage = () => {
      if (node.frontmatter.featuredImage) {
        if (isDesktop) {
          return (
              <Img
                className={classes.media}
                fixed={node.frontmatter.featuredImage.childImageSharp.fixed}
              />
          )
        } else {
          return (
            <>
              <div className={classes.imageWrap} style={{ minWidth: '100%' }}>
                <Img
                  className={classes.media}
                  fluid={node.frontmatter.featuredImage.childImageSharp.fluid}
                />                
                <Typography className={classes.bottomText} variant="subtitle1" component="p">
                  <small>{node.frontmatter.date}</small>
                </Typography>
              </div>
            </>
          )
        }
      }
      return <div />
    }
    const PostDate = () => {
      if (!node.frontmatter.featuredImage || isDesktop) {
        return (
          <Typography variant="subtitle1" component="p">
            <small>{node.frontmatter.date}</small>
          </Typography>
        )
      }
      return <></>
    }
    return (
      <Card variant="outlined" className={classes.root} key={node.fields.slug}>
        <Link
          className={classes.link}
          to={`/${node.frontmatter.type}${node.fields.slug}`}
          underline="none"
        >
          <CardActionArea className={classes.wrap}>
            <FeaturedImage />
            <CardContent className={contentClass}>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                  <PostDate />
                </Typography>
                <Typography
                  variant="h6"
                  component="p"
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                ></Typography>
              </CardContent>
          </CardActionArea>
        </Link>
      </Card>
    )
  })
}

export default RecentArticles
